<template>
  <div class="register-product">
    <page-title-action
      :title="pageTitle"
      :isFixedButton="isFixedButton"
      :isDetailProduct="isDetailProduct"
      :workflowData="workflowData"
      :isApprovedData="isApprovedData"
      :isHasData="isCheckChangeData"
      @handleActionByName="handleActionByName"
      >
    </page-title-action>
    <div class="product-information">
      <TitleBar :title="$t('cfp_emissions_product.label_information_product')">
        <ButtonCustome
          v-if="isDetailProduct && !isDisableInputProductData"
          icon="edit-information.svg" 
          iconHover="edit-information-hover.svg"
          iconDisable="edit-information.svg" 
          isSmallButton
          :isDisable="disableEditProductInformationBtn"
          :buttonText="$t('cfp_emissions_product.button_edit_product_information')"
          @action="handleEditProductInformation"
        >
        </ButtonCustome>
      </TitleBar>
      <ProductInfo 
        :declaredUnitList="declaredUnitList" 
        :productInfo="productInfo"
        :canEditProductInfo="!isDisableEditData"
        :isDetailProduct="isDetailProduct"
        :isReadOnly="disableEditProductInformation"
        :isDraftProduct="isDraftProduct"
        :key="keyInfor"
        :workflowData="workflowData"
        :isCheckValidate="isCheckValidate"
        @updateLayerBinding="handleUpdateLayerBinding"
        @updateProductInfo="handleUpdateProductInfo"
        @showErrorMessage="showErrorMessage"
        />
    </div>

    <div class="product-information boundary" :class="{'hide': !showBoundary && isDetailProduct}">
      <TitleBar v-if="isNewRegisterProduct && !showBoundary" :title="$t('cfp_emissions_product.label_boundary')"></TitleBar>
      <Boundary
        v-if="isNewRegisterProduct || showBoundary"
        :dataList="boundaries"
        :isDetailProduct="isDetailProduct"
        :workflowData="workflowData"
        :isDisableInputProductData="isDisableInputProductData"
        :isBoundaryForEmission="false"
        @updateBoundaries="handleUpdateBoundaries"
      />
      <div class="product-information actions" :class="showBoundary && 'is-showboundary'">
        <Button
          v-if="isDetailProduct"
          class="boundary-show-hide"
          :icon="!showBoundary ? 'products/boundary-show.svg' : 'products/boundary-hide.svg'"
          :iconHover="!showBoundary ? 'products/boundary-show-hover.svg' : 'products/boundary-hide-hover.svg'"
          :label="showHideBoundaryBtn"
          @action="clickShowHideBoundaryBtn"
          :key="keyButtonDetail"
        />
      </div>
    </div>

    <div class="product-information" style="margin-bottom: 80px;" v-if="isDetailProduct && workflowData.id">
      <TitleBar :title="$t('cfp_emissions_product.label_register_emissions')">
        <ButtonCustome
          v-if="isProductOwner"
          icon="icon-add.svg" 
          iconHover="icon-add-hover.svg"
          iconDisable="icon-add-disable.svg" 
          isSmallButton 
          :buttonText="$t('register_user_group.label_register')"
          @action="handleAddEmissions()"
        >
        </ButtonCustome>
      </TitleBar>
      <EmissionProductInfo
        :workflowData="workflowData"
      />
    </div>
    <div v-if="isMobile" ref="scrollElement">
      <page-title-action
        :title="$t('cfp_emissions_product.title_register_product')"
        :isFixedButton="isFixedButton"
        :workflowData="workflowData"
        :isApprovedData="isApprovedData"
        :isDetailProduct="isDetailProduct"
        hiddenIfDoNotHaveAnyButton
        @handleActionByName="handleActionByName"
      >
      </page-title-action>
    </div>
    <notification-popup
      :dialog="dialog.notification"
      :message="message.notification"
      :confirmText="confirmText"
      @submit="dialog.notification = false"
    />
    <question-popup
      :dialog="dialog.question"
      :message="message.question"
      :isSaveButtonDelete="isDeleteProduct"
      :confirmText="$t('popup.button_delete')"
      @close="dialog.question = false"
      @submit="handleDeleteProduct()"
    />
    <QuestionPopup
      :dialog="dialog.cancleSubmit"
      :confirm="true"
      :closeText="$t('popup.button_cancel')"
      :confirmText="$t('popup.button_cancel_request')"
      :message="message.cancelSubmit"
      @close="handleCloseDialogByType('cancleSubmit')"
      classes="register"
      @submit="submitCancelRequest()"
    />
    <approval-popup
      :dialog="dialog.questionApprove"
      :items="listStatusApproval"
      :confirmText="$t('b_register_product.button_decision')"
      @submit="approvalDataHandler"
      @close="handleCloseDialogByType('questionApprove')"
    />
    <notification-popup
      :dialog="dialog.error"
      :message="message.error"
      :isErrorImg="true"
      @submit="dialog.error = false"
    />
    <notification-popup :dialog="dialog.notificationSaved" :confirmText="confirmText" :message="message.savedChange" @submit="handleCloseNotificationSaved" />
    <SubmitDataPopup
      :dialog="dialog.submit"
      :listApprovers="listApprovers"
      :isLoadingSubmitting="isLoadingSubmitting"
      :isShowTitle="false"
      title=""
      @closeDialogSubmitData="handleCloseDialogSubmit"
      @submitHandler="handleSubmitProduct"
    />
    <notification-popup
      :dialog="dialog.submitSuccess"
      :message="message.submitSuccess"
      :confirmText="confirmText"
      @submit="handleClosePopupSubmitSuccess"
      classes="register"
    />
    <accept-cancel-popup
      :dialog="dialog.acceptCancel"
      :items="listStatusCancel"
      :confirmText="$t('b_register_product.button_decision')"
      @submit="handleAcceptRequestCancelSubmit"
      @close="handleCloseDialogByType('acceptCancel')"
    />

    <!-- case noti accept cancle -->
    <notification-popup
      :dialog="dialog.acceptCancleSuccess"
      :message="message.messageAcceptCancle"
      classes="register"
      @submit="handleClosePopupAcceptCancleSuccess"
    />
    <notification-popup
      v-if="dialog.cancleSuccess"
      :dialog="dialog.cancleSuccess"
      :message="message.messageCancleSuccess"
      @submit="handleClosePopupCancleSuccess"
      classes="register"
    />
    <!-- notification approved data done -->
    <notification-popup
      v-if="dialog.approvedData"
      :dialog="dialog.approvedData"
      :message="message.approvedData"
      @submit="handleClosePopupApprovedData"
      classes="register"
    />
  </div>
</template>
<script>
// UI import
import Button from '@/components/commonApp/Button';
import Boundary from "@/components/products/Boundary";
import TitleBar from '@/components/products/table/TitleBar';
import ProductInfo from "@/components/products/ProductInfo";
import ErrorPopup from '@/components/dialogs/error-popup.vue';
import QuestionPopup from '@/components/dialogs/question-popup';
import ApprovalPopup from '@/components/dialogs/approval-popup';
import ButtonCustome from '@/components/commonApp/ButtonIconCustom';
import NotificationPopup from '@/components/dialogs/notification-popup';
import AcceptCancelPopup from '@/components/dialogs/accept-cancel-popup';
import PageTitleAction from '@/components/products/common/page-title-action.vue';
import EmissionProductInfo from '@/components/products/table/EmissionProductInfo';
import SubmitDataPopup from '@/components/dialogs/ActionHeaderTable/submit-data-popup';

//logic import
import i18n from '@/lang/i18n';
import { ROUTES } from '@/router/constants';
import { mapActions, mapState, mapGetters } from 'vuex';
import { addThoundSandComma } from '@/utils/convertNumber';
import { validRangeDate } from '@/utils/datetimeFormat';

import { DECLARED_UNIT_LIST } from '@/constants/products/define-data';
import { formatNumberBySetting } from '@/concerns/newRegisterData/wijmo.helper';
import { ACTION_HEADER_TABLE, APPROVAL_TYPE, CANCEL_TYPE } from '@/constants/registerData';
import { STATUS_SUBMIT, STATUS_RETURN, STATUS_REPRENSENT_RETURN, STATUS_FIELD, STATUS_CANCEL_SUBMMITED } from '@/constants/status';
import { ROLE } from '@/constants/role';

// api import
import { getUnitListApi } from '@/api/product'
import { actionProductWorkflow, getDetailProduct, duplicateProduct, deleteProduct, actionNotificationProduct, actionEmissionsProduct } from '@/api/product/emissionsProduct';
import _ from 'lodash';

export default {
  components: {
    Button,
    TitleBar,
    Boundary,
    ErrorPopup,
    ProductInfo,
    ApprovalPopup,
    ButtonCustome,
    QuestionPopup,
    PageTitleAction,
    SubmitDataPopup,
    NotificationPopup,
    AcceptCancelPopup,
    EmissionProductInfo
  },
  data() {
    return {
      isCheckLang: 'en',
      isFixedButton: true,
      isMobile: window.innerWidth < 1024,
      showTooltip: {
        productUnit: false,
        declaredUnit: false,
        productCost: false,
      },
      declaredUnitList: [],
      declaredUnit: 'L',
      workflowData: {},
      boundaries: [],
      productInfo: {
        name: '',
        code: '',
        unit: '',
        declareUnit: null,
        product_quantity: null,
        startDate: null,
        endDate: null,
        lotNumber: null,
        organizational_division: null,
        company_name: null,
        business_name: null,
        country: null,
        layer_3: null,
        layer_4: null,
        layer_5: null,
        layer_6: null,
        branch_id: null
      },
      showBoundary: false,
      listStatusApproval: [],
      isSubmitToMyseft: false,
      keyInfor: 0,
      isUpdateDraft: false,
      dialog: {
        notification: false,
        notificationSaved: false,
        error: false,
        question: false,
        submit: false,
        questionApprove: false,
        cancleSubmit: false,
        submitSuccess: false,
        acceptCancel: false,
        acceptCancleSuccess: false,
        cancleSuccess: false,
        approvedData: false
      },
      message: {
        notification: '',
        error: '',
        question: '',
        savedChange: this.$t('facility_management.message_saved_changes'),
        approve: this.$t('register_data.popup_message_update_approved_data'),
        cancelSubmit: this.$t('popup.popup_cancel_approve_product'),
        submitSuccess: this.$t('register_data.popup_message_submit_sucess'),
        messageAcceptCancle: '',
        messageCancleSuccess: this.$t('popup.button_cancel_approve_product'),
        approvedData: ''
      },
      disableEditProductInformation: false,
      isDeleteProduct: false,
      isApproveData: false,
      listStatusCancel: [
        {
          id: CANCEL_TYPE.ACCEPT,
          name: this.$t('register_data.radio_accept_cancel_submit'),
          description: this.$t('register_data.description_accept_cancel_submit'),
        },
        {
          id: CANCEL_TYPE.REJECT,
          name: this.$t('register_data.radio_reject_cancel_submit'),
          description: this.$t('register_data.description_reject_cancel_submit'),
        },
      ],
      isCheckChangeDataInfo : true,
      isCheckChangeDataBoundaries:true,
      productInfoClone: null,
      boundariesClone: null,
      isCheckValidate: false,
      layerBinding: [],
      keyButtonDetail: 0
    }
  },
  created() {
    // show popup duplicate productr success
    if (this.$route.query.isDuplicate === 'true') {
      this.dialog.notification = true;
      this.message.notification = this.$t("cfp_emissions_product.message_duplicate_product");
      const newQuery = {
        ...this.$route.query,
      }
      delete newQuery.isDuplicate;
      this.$router.push({name: "DetailProduct", params: {id: this.$route?.params?.id}, query: newQuery});
    };
    this.productInfoClone =_.cloneDeep(this.productInfo);
    this.boundariesClone =_.cloneDeep(this.boundaries)
  },
  async mounted() {
    document.addEventListener('scroll', this.updateActionPosition);
    if (window.innerWidth < 1024) {
      this.isFixedButton = true;
    }
    window.addEventListener('resize', this.handleResize);
    // check is new register
    // TODO:
    
    // else call api get detail data from id
    this.getListUnit()
    if (this.$route?.params?.id) {
      this.disableEditProductInformation = true
      await Promise.all([this.getApproversList(), this.getDetailProductById()]);
    }
    // check field, boundary, emissions records status
    //TODO: update BreadCrum
    this.updateBreadCrumb(this.breadCrumb())

    if(this.$route.query?.is_allow_cancel) { // handle cancel submit
      await this.handleApprovalCancleSubmit();
    }
  },
  computed: {
    ...mapState('userData', ['currentUser']),
    ...mapGetters('newRegisterData', ['isLoadingSubmitting']),
    ...mapState('approval', ['listApprovers']),
    isNewRegisterProduct() {
      return this.$route.name === "RegisterProduct";
    },
    isDetailProduct() {
      return !!this.$route?.params?.id;
    },
    showHideBoundaryBtn() {
      return !this.showBoundary ? this.$t("product_boundary.button_show_boundary") : this.$t("product_boundary.button_hide_boundary");
    },
    disableEditProductInformationBtn() {
      return this.isDetailProduct && this.workflowData?.status === STATUS_FIELD.STATUS_SUBMIT;
    },
    pageTitle() {
      return this.isDetailProduct ? this.$t('cfp_emissions_product.title_detail_product') : this.$t('cfp_emissions_product.title_register_product');
    },
    isDisableEditData() {
      return [STATUS_FIELD.STATUS_SUBMIT].includes(this.workflowData?.status);
    },
    confirmText() {
      return i18n.locale !== 'ja' ? this.$t('list_menu.label_decision') : "OK"
    },
    isApprovedData() {
      return [STATUS_FIELD.STATUS_APPROVED, STATUS_FIELD.STATUS_REPRENSENT_APPROVED].includes(this.workflowData?.status);
    },
    isDisableInputProductData() {
      // Checking if status is DRAFT, RETURN, only owner allow edit product
      if ([STATUS_FIELD.STATUS_DRAFT, STATUS_FIELD.STATUS_RETURN, STATUS_FIELD.STATUS_REPRENSENT_RETURN].includes(this.workflowData?.status)) {
        return !this.workflowData?.is_owner;
      }

      return [
        STATUS_FIELD.STATUS_SUBMIT,
        STATUS_FIELD.STATUS_CANCEL_SUBMMITED,
        STATUS_FIELD.STATUS_APPROVED,
        STATUS_FIELD.STATUS_REPRENSENT_APPROVED
      ].includes(this.workflowData?.status);
    },
    isDraftProduct() {
      return [STATUS_FIELD.STATUS_DRAFT].includes(this.workflowData?.status);
    },
    isProductOwner() {
      return this.workflowData?.is_owner === 1;
    },
    isCheckChangeData(){
      if(this.isNewRegisterProduct && !this.isDetailProduct){
        return this.isCheckChangeDataInfo && this.isCheckChangeDataBoundaries
      }
      return false
    },
  },
  methods: {
    ...mapActions('commonApp', ['updateBreadCrumb']),
    ...mapActions('approval', ['getApproversList']),
    ...mapActions("product", ["actionUpdateNotification"]),
    handleResize() {
      this.isMobile = window.innerWidth < 1024;
    },
    breadCrumb() {
      const breadCrumb = [
        {
          text: this.$t('b_list_submited.hyperlink_home'),
          disabled: false,
          href: `${ROUTES.PRODUCTS_EMISSION}/${ROUTES.PRODUCT_LIST}`,
        },
      ];
      const typeDetail = this.$route.query?.type //this.$route?.params?.id
      if (!this.$route?.params?.id) {
        breadCrumb.push({
          text: this.$t('cfp_emissions_product.title_register_product'), //TODO: update text
          disabled: true,
          href: `${ROUTES.PRODUCTS_EMISSION}/${ROUTES.PRODUCT_LIST}`,
        });
      } else  {
        if(typeDetail === 'detail') {
          breadCrumb.push({
            text: this.$t('cfp_emissions_product.title_list_products'),
            disabled: false,
            href: `${ROUTES.PRODUCTS_EMISSION}/${ROUTES.PRODUCT_LIST}`,
          });
        }

        if(typeDetail === 'submit') {
          breadCrumb.push({
            text: this.$t('b_list_submited.label_application_status'),
            disabled: false,
            href: `${ROUTES.PRODUCTS_EMISSION}/${ROUTES.PRODUCT_SUBMITTED_LIST}`,
          });
        }

        if(typeDetail === 'approval') {
          breadCrumb.push({
            text: this.$t('left_menu.button_approval'),
            disabled: false,
            href: `${ROUTES.PRODUCTS_EMISSION}/${ROUTES.PRODUCT_APPROVED_LIST}`,
          });
        }

        breadCrumb.push({
          text: this.$t('cfp_emissions_product.hyperlink_product_name', {productName: this.workflowData?.name}), //TODO: update text
          disabled: true,
          href: `${ROUTES.PRODUCTS_EMISSION}/${ROUTES.PRODUCT_LIST}`,
        });
      }
      return breadCrumb;
    },
    updateActionPosition() {
        const scrollElementTop = this.$refs.scrollElement?.offsetTop; // Vị trí của phần tử
        const scrollPosition = window.scrollY + window.innerHeight; // Vị trí scroll hiện tại
        if (scrollPosition > (scrollElementTop + 215)) { // scrollElementTop + 215 ===  the space between the buttons with top screen
          this.isFixedButton = false;
        } else {
          this.isFixedButton = true;
        }
    },
    getSettingIcon(image) {
      if (image) {
        return require(`@/assets/icons/${image}`);
      }
      return '';
    },
    handleUpdateBoundaries(dataList) {
      if(this.boundariesClone.length === 0){
        this.boundariesClone = _.cloneDeep(dataList)
      }
      this.boundaries = dataList;
      this.isCheckChangeDataBoundaries = _.isEqual(this.boundaries,this.boundariesClone)
    },
    handleUpdateProductInfo(newVal) {
      this.productInfo = newVal;
      this.isCheckChangeDataInfo = _.isEqual(this.productInfo,this.productInfoClone);
    },
    handleUpdateLayerBinding(layerBinding) {
      this.layerBinding = layerBinding
    },
    showErrorMessage(error) {
      this.message.error = error;
      this.dialog.error = true
    },
    async getListUnit() {
      try {
        const listUnit = await getUnitListApi({is_cfp:1});
        listUnit?.data?.forEach(item => {
          this.declaredUnitList.push({
            key: item.key,
            value: item.name
          })
        })
      } catch (error) {
        console.warn(error)
      }
    },
    async handleAddEmissions() {
      try {
        const response = await actionEmissionsProduct('post', this.$route?.params?.id);
        this.$router.push({name: "EmissionsDetailProduct", params: {emissionId: response.data.id}, query: {...this.$route.query}}).catch(() => {});
      } catch (err) {
        console.log(err);
      }
    },
    handleCheckValidateBoundary() {
      let isEmptyBoundary = false
      let isAnyEmptyProcess = false
      let isDontHasAnyProcessInBoundary = false
      let isDontHasAnyInBoundary = false

      if(this.boundaries?.length <= 0) {
        isDontHasAnyInBoundary = true
      }
      this.boundaries.forEach(boundary => {
        if(!boundary?.name?.trim()) {
          isEmptyBoundary = true
        }
        if(boundary?.processes?.length <= 0) {
          isDontHasAnyProcessInBoundary = true
        }
        boundary?.processes?.forEach(processItem => {
          if(!processItem?.name?.trim()) {
            isAnyEmptyProcess = true
          }
        })
      })

      return { isEmptyBoundary, isAnyEmptyProcess, isDontHasAnyInBoundary, isDontHasAnyProcessInBoundary }
    },
    checkValidateAndShowPopupIfError(action = '') {
      let messageList = [];
      if (!this.productInfo.name?.trim()) {
        messageList.push(this.$t("cfp_emissions_product.message_required_product_name"))
      }
      if (!this.productInfo.code) {
        messageList.push(this.$t("cfp_emissions_product.message_required_product_code"))
      }
      if (this.productInfo.unit === '') {
        messageList.push(this.$t("cfp_emissions_product.message_required_unit"))
      }

      if(!this.productInfo.lotNumber?.trim()) {
        messageList.push(this.$t("cfp_emissions_product.message_registered_emty_lotNumber"))
      }
      // Validate start date and end date
      if(this.productInfo.startDate !== null && this.productInfo.endDate !== null && !validRangeDate(this.productInfo.startDate, this.productInfo.endDate)) {
        messageList.push(this.$t("cfp_emissions_product.message_greater_than_time_fields"))
      }

      //check validate boundary, process in boundary
      const { isEmptyBoundary, isAnyEmptyProcess, isDontHasAnyInBoundary, isDontHasAnyProcessInBoundary } = this.handleCheckValidateBoundary()
      if(isEmptyBoundary) {
        messageList.push(this.$t("cfp_emissions_product.message_required_boundary"))
      }

      if(isAnyEmptyProcess) {
        messageList.push(this.$t("cfp_emissions_product.message_required_process"))
      }

      if(isDontHasAnyInBoundary && action !== 'saveDraft') {
        messageList.push(this.$t("cfp_emissions_product.message_empty_boundary"))
      }

      if(isDontHasAnyProcessInBoundary && action !== 'saveDraft') {
        messageList.push(this.$t("cfp_emissions_product.message_empty_process"))
      }

      if(messageList.length > 0) { //show message here
        this.dialog.notification = true;
        this.message.notification = messageList.join('\n')
        return false
      }
      if (this.productInfo.unit.length > 10) {
        return false;
      }
      return true
    },
    clickSaveDraftBtn() {
      setTimeout(() => {
        const isValidData = this.checkValidateAndShowPopupIfError('saveDraft');
        if (!isValidData) {
          return
        } 
        
        if (this.isDetailProduct) {
          this.updateDetailProduct();
        } else {
          this.handleSaveDraftProduct();
        }
      }, 300); // waiting for emit data from child component
    },
    clickCancelSaveDraftBtn() {
      this.dialog.question = true
      this.message.question = this.$t("cfp_emissions_product.message_delete_product")
      this.isDeleteProduct = true
    },
    handleSubmitQuestionPopup() {
    },
    handleShowError(errors) {
      if (errors.code === "cfp.forbidden_permission") {
        this.message.error = errors.message;
        this.dialog.error = true
      } else {
        this.message.error = Object.keys(errors.errors).map((key) => errors.errors[key]?.join('\n')).join('\n');
        this.dialog.error = true
      }
    },
    getDataPayloadProductInfo(id = null) {
      const { product_quantity, product_quantity_original } = this.productInfo;
      const productQuantity = product_quantity === product_quantity_original ? product_quantity_original 
                            : product_quantity ? formatNumberBySetting(product_quantity) : null;
      const payload = {
        contractor_id: this.currentUser?.user?.contractor_id,
        name: this.productInfo.name?.length > 255 ? this.productInfo?.name?.slice(0, 255) : this.productInfo.name,
        code: this.productInfo.code?.length > 255 ? this.productInfo.code.slice(0, 255) : this.productInfo.code,
        product_unit: this.productInfo.unit || '',
        declaration_unit: this.productInfo.declareUnit || '',
        product_quantity: productQuantity,
        start_date: this.productInfo.startDate,
        end_date: this.productInfo.endDate,
        lot_number: this.productInfo.lotNumber?.length > 255 ? this.productInfo?.lotNumber?.slice(0, 255) : this.productInfo.lotNumber,
        organizational_division: this.productInfo.organizational_division || null,
        company_name: this.productInfo.company_name || null,
        business_name: this.productInfo.business_name || null,
        country: this.productInfo.country || null,
        layer_3: this.productInfo.layer_3 || null,
        layer_4: this.productInfo.layer_4 || null,
        layer_5: this.productInfo.layer_5 || null,
        layer_6: this.productInfo.layer_6 || null,
        branch_id: this.productInfo.branch_id || null
      }
      if(id) { // add id for case edit data
        payload.id = id
      }
      return this.trimValues(payload)
    },
    trimValues(obj) {
      return Object.fromEntries(
        Object.entries(obj).map(([key, value]) => [
          key,
          typeof value === 'string' ? value.trim() : value
        ])
      );
    },
    getDataPayloadProductBoundary(isEditDataProduct = false) {
      const responeData = []
      this.boundaries.forEach((boundary, boundaryIndex) => {
        const processListByBoundary = boundary.processes.map((process, processIndex) => {
          const processItem = {
            name: process.name?.length > 255 ? process.name?.trim().slice(0, 255) : process.name?.trim(),
            cut_off: process.cut_off ? 1 : 0,
            position: processIndex + 1,
          }

          if(isEditDataProduct) { // add id detail for case edit
            processItem.id = process.id || null
            processItem.boundary_id = boundary.id || null
            processItem.product_id = this.workflowData.id
          }
          return processItem
        })

        const boundaryItem = {
          name: boundary.name?.length > 255 ? boundary.name?.trim().slice(0, 255) : boundary.name?.trim(),
          position: boundaryIndex + 1,
          processes: processListByBoundary
        }
        if(isEditDataProduct) { // add id detail for case edit
          boundaryItem.id = boundary.id || null
          boundaryItem.product_id = this.workflowData.id
        }
        responeData.push(boundaryItem)
      })
      return responeData
    },
    async handleSaveDraftProduct() {
      const payload = {
        action: "saveDraft",
        data: {
          product: this.getDataPayloadProductInfo(),
          boundaries: this.getDataPayloadProductBoundary(),
        }
      }
      try {
        const responseData = await actionProductWorkflow(payload, 'post');
        this.disableEditProductInformation = true
        const newQuery = {
          ...this.$route.query,
          type: 'detail',
        }
        await this.$router.replace({ name: "DetailProduct", params: {id: responseData?.id}, query: newQuery });
        await this.getDetailProductById(responseData?.id)
        this.updateBreadCrumb(this.breadCrumb())
        this.keyInfor ++
      } catch(err) {
        this.handleShowError(err)
      }
    },
    async updateDetailProduct() {
      try {
        const payload = {
          action: "update",
          data: {
            product: this.getDataPayloadProductInfo(this.workflowData.id),
            boundaries: this.getDataPayloadProductBoundary(true) //true is flag isEdit
          }
        }

        await actionProductWorkflow(payload, 'put');
        this.dialog.notificationSaved = true
        this.isUpdateDraft = true
      } catch(err) {
        this.handleShowError(err)
      }
    },
    async handleDeleteProduct() { //handle Delete product
      try {
        if(!this.workflowData?.id) {
          this.$router.push({ name: "ProductsEmissionNew" }).catch(() => {});
          return
        }
        await deleteProduct(this.workflowData.id)
        this.$router.push({ name: "ProductsEmissionNew" }).catch(() => {});
      } catch (error) {
        this.handleShowError(error)
      }
    }, 
    clickShowHideBoundaryBtn() {
      this.showBoundary = !this.showBoundary;
      this.keyButtonDetail ++; // re-render button detail
    },
    handleShowPopupSubmitProduct() {
      const isValidData = this.checkValidateAndShowPopupIfError();
      if (!isValidData) {
        return
      }
      const isHasAnyLayerData = this.layerBinding.some(keyItem => this.productInfo[keyItem])
      const isValidBranch = !this.productInfo.branch_id && isHasAnyLayerData
      if(isValidBranch) {
        this.isCheckValidate = true
        return
      }
      this.dialog.submit = true
    },
    async handleSubmitProduct(approvalFata) {      
      try {
        const payload = {
          action: "submit",
          data: {
            product: {
              ...this.getDataPayloadProductInfo(this.workflowData.id),
              selected_approver: approvalFata?.selected_approver,
              comment: approvalFata?.comment,
              title: '' //empty for this time
            },
            boundaries: this.getDataPayloadProductBoundary(true),
          }
        }
        const isEmtyBranchId = !payload.data.product.branch_id
        if(isEmtyBranchId) {
          payload.data.product = this.clearLayers(payload.data.product);
        }
        const responeData = await actionProductWorkflow(payload, 'put');
        this.isSubmitToMyseft = responeData?.data?.is_auto_approve ? true : false //TODO: update consiton when api update
        this.dialog.submit = false
        this.dialog.submitSuccess = true
      } catch(err) {
        this.dialog.submit = false
        this.handleShowError(err)
      }
    },
    handleCloseDialogSubmit() {
      this.dialog.submit = false
    },
    handleCloseApprovePopup() {
      this.dialog.questionApprove = false
    },
    handleShowPopupApprover() {
      this.handleGetDescriptionPopupByWorkflow()
      this.dialog.questionApprove = true
    },
    handleCancelSubmit() {
      this.dialog.cancleSubmit = true
    },
    handleCloseDialogByType(type) {
      this.dialog[type] = false
    },
    handleShowAlowCancelSubmitPopup() {
      this.dialog.acceptCancel = true
    },
    async clickDuplicateProductBtn() {
      try {
        const response = await duplicateProduct(this.workflowData.id);
        const newQuery = {
          ...this.$route.query,
          isDuplicate: 'true'
        }

        await this.$router.replace({name: "DetailProduct", params: {id: response.id}, query: newQuery})
        window.location.reload();
      } catch(err) {
        this.handleShowError(err)
      }
    },
    handleEditProductInformation() {
      this.disableEditProductInformation = !this.disableEditProductInformation;
    },
    async getDetailProductById(id) {
      try {
        const idProduct = id || this.$route?.params?.id
        const response = await getDetailProduct(idProduct);
        this.workflowData = response.data;
        const branchData = response.data.branch || response.data
        this.productInfo = {
          name: response.data.name,
          code: response.data.code,
          unit: response.data.product_unit,
          declareUnit: response.data.declaration_unit,
          startDate: response.data.start_date,
          endDate: response.data.end_date,
          lotNumber: response.data.lot_number,
          product_quantity: response.data.product_quantity,
          product_quantity_original: response.data.product_quantity,
          organizational_division: branchData.organizational_division,
          company_name: branchData.company_name,
          business_name: branchData.business_name,
          country: branchData.country,
          layer_3: branchData.layer_3,
          layer_4: branchData.layer_4,
          layer_5: branchData.layer_5,
          layer_6: branchData.layer_6,
          branch_id: response.data.branch?.id || null
        }
        this.boundaries = response.data.boundaries;
        if(this.workflowData?.unread_flg) {
          this.handleUpdateNotification(this.workflowData)
        }
        if (response.data) {
          if (this.$route.query.type === "submit" &&
          this.currentUser?.user.role_id === ROLE.APPROVAL &&
          this.workflowData?.is_approver === 1 &&
          this.workflowData?.is_owner !== 1 &&
          this.workflowData?.status === STATUS_FIELD.STATUS_SUBMIT
          ) {
            const newQuery = {
              ...this.$route.query,
              type: "approval",
            }
            this.$router.replace({ name: "DetailProduct", query: newQuery}).catch(() => {});
          }
        }
      } catch(err) {
        // Redirect to list approval if appover can not view details
        if (this.$route?.query?.type === 'approval') {
          this.$router.push({ name: "ProductsApprovalNew", query: this.$route?.query}).catch(() => {});
        } else {
          const newQuery = {...this.$route?.query};
          delete newQuery?.type;
          this.$router.push({ name: "ProductsEmissionNew", query: newQuery }).catch(() => {});
        }
      }
    },
    handleGetDescriptionPopupByWorkflow() {
      if (this.workflowData?.is_approver === 1) {//TODO: update condition by workflowData
        this.listStatusApproval = [
          {
            id: APPROVAL_TYPE.APPROVE,
            name: this.$t('register_data.radio_approve'),
            description:  this.$t('register_data.description_approve'),
          },
          {
            id: APPROVAL_TYPE.REJECT,
            name: this.$t('register_data.radio_reject'),
            description: this.$t('register_data.description_reject'),
          },
        ];
      } else {
        this.listStatusApproval = [
          {
            id: APPROVAL_TYPE.APPROVE,
            name: this.$t('register_data.radio_proxy_approve'),
            description: this.$t('register_data.description_proxy_approve'),
          },
          {
            id: APPROVAL_TYPE.REJECT,
            name: this.$t('register_data.radio_proxy_reject'),
            description: this.$t('register_data.description_proxy_approve'),
          },
        ];
      }
    },
    handleGetStatusApprovalForPayload(status, isApproval) {
      let response = null
      if (status === APPROVAL_TYPE.APPROVE) {
        response = isApproval ? STATUS_FIELD.STATUS_APPROVED : STATUS_FIELD.STATUS_REPRENSENT_APPROVED;
      } else {
        response = isApproval ? STATUS_FIELD.STATUS_RETURN : STATUS_FIELD.STATUS_REPRENSENT_RETURN;
      }
      return response
    },
    async approvalDataHandler(approvalFormData) {
      try {
        this.dialogApprovalData = false
        const status = this.handleGetStatusApprovalForPayload(approvalFormData.status, this.workflowData?.is_approver === 1)
        const payload = {
          action: ACTION_HEADER_TABLE.APPROVED_DATA,
          data: {
            id: this.workflowData.id,
            status: status,
            comment: approvalFormData?.comment?.length > 255 ? approvalFormData.comment.substr(0, 255) : approvalFormData.comment,
          }
        }

        await actionProductWorkflow(payload, 'put');
        this.dialog.questionApprove = false
        this.message.approvedData = this.getMessApprovedFromStatus(status)
        this.dialog.approvedData = true
      } catch(error) {
        this.dialog.questionApprove = false
        this.dialogApprovalData = false
        this.handleShowError(error)
      }
    },
    getMessApprovedFromStatus(status) {
      let message = ''
      if(status === STATUS_FIELD.STATUS_APPROVED) {
        message = this.$t('register_data.popup_message_accept_approve')
      } else if(status === STATUS_FIELD.STATUS_REPRENSENT_APPROVED) {
        message = this.$t('register_data.popup_message_accept_approver_approve')
      } else if(status === STATUS_FIELD.STATUS_RETURN) {
        message = this.$t('register_data.popup_message_return_approve')
      } else {
        message = this.$t('register_data.popup_message_return_approver_approve')
      }
      return message
    },
    handleClosePopupApprovedData() {
      this.$router.push({ name: "ProductsApprovalNew" }).catch(() => {});
    },
    async submitCancelRequest() {
      try {
        this.dialog.cancleSubmit = false
        const payload = {
          action: ACTION_HEADER_TABLE.CANCEL_SUBMITTED,
          data: { id: this.workflowData.id }
        }
        await actionProductWorkflow(payload, 'put');
        this.dialog.cancleSuccess = true
      } catch (error) {
        this.dialog.cancleSubmit = false
        this.handleShowError(error)
      }
    },
    handleClosePopupCancleSuccess() {
      this.dialog.cancleSuccess = false
      this.$router.push({ name: "ProductsEmissionNew" }).catch(() => {});
    },
    handleClosePopupSubmitSuccess() {
      this.dialog.submitSuccess = false;
      this.isCheckValidate = false
      if(this.isSubmitToMyseft) {
        this.$router.push({ name: "ProductsApprovalNew" }).catch(() => {});
      } else {
        this.$router.push({ name: "ProductsListSubmittedNew" }).catch(() => {});
      }
    },
    async handleApprovalCancleSubmit() {
      try {
        const payload = {
          action: ACTION_HEADER_TABLE.ACCEPT_CANCEL_SUBMITTED,
          data: {
            id: this.workflowData.id
          },
        }
        await actionProductWorkflow(payload, 'put', { is_allow_cancel: CANCEL_TYPE.ACCEPT });
        this.$router.push({ name: "ProductsApprovalNew" }).catch(() => {});
      } catch (error) {
        this.handleShowError(error)
      }
    },
    async handleAcceptRequestCancelSubmit(acceptCancelFormData) {
      try {
        this.dialog.acceptCancel = false
        let is_allow_cancel = null;
        if (acceptCancelFormData.status === CANCEL_TYPE.ACCEPT) {
          is_allow_cancel = CANCEL_TYPE.ACCEPT;
        } else {
          is_allow_cancel = CANCEL_TYPE.REJECT;
        }
        const payload = {
          action: ACTION_HEADER_TABLE.ACCEPT_CANCEL_SUBMITTED,
          data: {
            id: this.workflowData.id,
            comment: acceptCancelFormData?.comment?.length > 255 ? acceptCancelFormData.comment.substr(0, 255) : acceptCancelFormData.comment,
          }
        }

        await actionProductWorkflow(payload, 'put', { is_allow_cancel: is_allow_cancel })
        // show popup for user
        this.dialog.acceptCancleSuccess = true
        this.message.messageAcceptCancle = acceptCancelFormData.status === CANCEL_TYPE.ACCEPT ? this.$t('register_data.popup_message_accept_cancel_approve') : this.$t('register_data.popup_message_accept_cancel_reject');

      } catch (error) {
        this.handleShowError(error)
      }
    },
    handleClosePopupAcceptCancleSuccess() {
      this.dialog.acceptCancleSuccess = false
      const newQuery = {
        ...this.$route.query,
        type: 'approval'
      }
      this.$router.push({ name: "ProductsApprovalNew", query: newQuery }).catch(() => {});
    },
    handleCloseNotificationSaved() {
      this.dialog.notificationSaved = false
      if(this.isUpdateDraft) {
        location.reload()
      }
    },
    handleUpdateNotification(dataWorkFollow) {
      if (
        (dataWorkFollow.is_approver === 1 && [STATUS_SUBMIT, STATUS_CANCEL_SUBMMITED].includes(dataWorkFollow.status)) || // cal API if user is approver
        (dataWorkFollow.is_owner === 1 &&[STATUS_RETURN, STATUS_REPRENSENT_RETURN].includes(dataWorkFollow.status))) {
          if ([STATUS_SUBMIT, STATUS_RETURN, STATUS_REPRENSENT_RETURN, STATUS_CANCEL_SUBMMITED].includes(dataWorkFollow.status)) {
            const params = {
              id: dataWorkFollow.id,
            };
            actionNotificationProduct({...params}, 'put').then(() => {
              actionNotificationProduct({}, 'get').then(res => {
                this.actionUpdateNotification(res.data)
              })
            });
          }
        }
    },
    handleActionByName(nameAction) {
      switch (nameAction) {
        case 'clickSaveDraftBtn':
          this.clickSaveDraftBtn()
          break;
        case 'clickCancelSaveDraftBtn':
          this.clickCancelSaveDraftBtn()
          break;
        case 'clickSubmitBtn':
          this.handleShowPopupSubmitProduct()
          break;
        case 'clickDuplicateProductBtn':
          this.clickDuplicateProductBtn()
          break;
        case 'clickApproveBtn':
          this.handleShowPopupApprover()
          break;
        case 'clickCancelSubmitProductBtn':
          this.handleCancelSubmit()
          break;
        case 'clickAllowCancelSubmitProductBtn':
          this.handleShowAlowCancelSubmitPopup()
          break;
        default:
          break;
      }
    },

    clearLayers (object) {
      return {
        ...object,
        country: null,
        layer_3: null,
        layer_4: null,
        layer_5: null,
        layer_6: null,
      }
    }
  },
  beforeDestroy() {
    document.removeEventListener("scroll", this.updateActionPosition);
    window.removeEventListener('resize', this.handleResize);
  },
  watch: {
    $route : {
      async handler(newVal, oldVal) {
        if((oldVal?.params?.id || oldVal?.params?.emissionId) && newVal?.name === 'RegisterProduct') {
          // reset product inform
          this.workflowData = {}
          this.productInfo = {
            name: '',
            code: '',
            unit: '',
            declareUnit: null,
            product_quantity: null,
            startDate: null,
            endDate: null,
            lotNumber: null,
            organizational_division: null,
            company_name: null,
            business_name: null,
            country: null,
            layer_3: null,
            layer_4: null,
            layer_5: null,
            layer_6: null,
            branch_id: null
          }
          this.disableEditProductInformation = false
          this.updateBreadCrumb(this.breadCrumb())
          this.keyInfor ++
        }
        if (this.$route?.params?.id) {
          this.disableEditProductInformation = true
          await Promise.all([this.getApproversList(), this.getDetailProductById()]);
          this.updateBreadCrumb(this.breadCrumb())
        }
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.register-product {
  position: relative;
  .product-information {
    display: flex;
    flex-flow: column;
    background: $bgCusLight;
    margin: 0 -20px;
    &-header {
      color: $monoWhite;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px;
      letter-spacing: 0.42px;
      height: 44px;
      padding: 8px 20px;
      gap: 16px;
      background: $bgDeep;
    }
    &.boundary {
      background: unset;
      &.hide {
        background: $bgLight;
      }
      .boundary-show-hide {
        width: 190px;
        
        margin: 24px auto;
      }
    }
    &.actions {
      &.is-showboundary {
        .boundary-show-hide {
          margin-top: 24px;
        }
      }
    }
    ::v-deep .input-field {
      .has-error {
        .v-text-field__details {
          padding-top: 0;
        }
      }
    }
  }
}
@include desktop {
  .register-product {
    .product-information {
      margin: 0;
      &.boundary {
        .boundary-show-hide {
          margin: 0 40px 24px;
        }
      }
    }
  }
}
</style>