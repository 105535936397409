<template>
  <div class="product-informations">
    <!-- product name infor -->
    <div class="product-informations-form name">
      <div class="product-informations-field">
        <div class="product-informations-field-title">
          <div class="text">{{ $t('b_register_product.label_product_name') }}</div>
          <div class="required-text">{{ $t('b_register_product.label_essential') }}</div>
        </div>
        <InputField 
          v-if="!(isReadOnly && isDetailProduct)"
          :inputValue="productInfoState.name"
          :isClearField="true"
          @update:inputValue="handleUpdateProduct($event, 'name')"
          @blur="autofillCodeField"
          @clearDataField="clearDataField"
          class="product-informations-field-input"
        />
        <InputDisable v-else :text="productInfoState.name" />
      </div>

      <div class="product-informations-field">
        <div class="product-informations-field-title">
          <div class="text">{{ $t('b_register_product.label_product_code') }}</div>
          <div class="required-text">{{ $t('b_register_product.label_essential') }}</div>
        </div>
        <InputField
          v-if="!(isReadOnly && isDetailProduct)"
          :inputValue="productInfoState.code"
          :isReadOnly="!productInfo.name || isMappingNameCode"
          class="product-informations-field-input"
          @update:inputValue="handleUpdateProduct($event, 'code')"
        />
        <InputDisable v-else :text="productInfoState.code" />
      </div>
    </div>

    <!-- product unit infor -->
    <div class="product-informations-form unit">
      <div class="product-informations-field">
        <div class="product-informations-field-title">
          <div class="text">{{ $t('cfp_emissions_product.label_product_unit') }}</div>
          <div class="required-text">{{ $t('b_register_product.label_essential') }}</div>
          <Tooltip :tooltipText="$t('cfp_emissions_product.tooltip_description_product_unit')" />
        </div>
        <InputField 
          v-if="!(isReadOnly && isDetailProduct)"
          :inputValue="productInfoState.unit"
          :errorMessages="productErrorMessages.productUnitErrorMessages"
          :isReadOnly="isReadOnly && isDetailProduct"
          class="product-informations-field-input"
          @update:inputValue="handleUpdateProduct($event, 'unit')"
          @blur="handleCheckProductUnit"
          @focus="resetErrorMessage('productUnitErrorMessages')"
        />
        <InputDisable v-else :text="productInfoState.unit" />
      </div>

      <div class="product-informations-field">
        <div class="product-informations-field-title">
          <div class="text">{{ $t('cfp_emissions_product.label_declared_unit') }}</div>
          <Tooltip :tooltipText="$t('cfp_emissions_product.tooltip_description_declared_unit')" />
        </div>
        <InfiniteScroll
          :items="declaredUnitList" 
          :model="productInfoState.declareUnit" 
          item-text="value" 
          item-value="key"
          :isDisabled="(!canEditProductInfo || isReadOnly) && isDetailProduct" 
          typeList="unit"
          :isReadOnly="isReadOnly && isDetailProduct"
          :isCfp="true"
          @updateMasterDb="updateDeclareUnit"
          @focus="resetErrorMessage('productDeclareUnitErrorMessage')"
          @blur="handleCheckProductDeclareUnit(productInfoState.declareUnit)"
          :label="getLabelDetail(productInfoState.declareUnit)"
        />
      </div>

      <div class="product-informations-field">
        <div class="product-informations-field-title">
          <div class="text">{{ $t('cfp_emissions_product.label_product_cost') }}</div>
          <Tooltip :tooltipText="$t('cfp_emissions_product.tooltip_description_product_cost')" />
        </div>
        <InputField 
          v-if="!(isReadOnly && isDetailProduct)"
          :inputValue="productInfoState.product_quantity"   
          :isNumberType="true"
          :isReadOnly="isReadOnly && isDetailProduct"
          class="product-informations-field-input"
          @update:inputValue="handleUpdateProduct($event, 'product_quantity')"  
          @blur="handleCheckProductQuantity"
          @focus="resetErrorMessage('productQuantityErrorMessages')"
        />
        <InputDisable v-else :text="formatNumber(productInfoState.product_quantity) || getLabelDetail(productInfoState.product_quantity)" />
      </div>
    </div>
    <div class="product-informations-form lot-number">
      <div class="product-informations-field">
        <div class="product-informations-field-title">
          <div class="text">{{ $t('cfp_emissions_product.label_lot_number') }}</div>
          <div class="required-text">{{ $t('b_register_product.label_essential') }}</div>
        </div>
        <InputField v-if="!(isReadOnly && isDetailProduct)" :inputValue="productInfoState.lotNumber"
          @update:inputValue="handleUpdateProduct($event, 'lotNumber')" class="product-informations-field-input" />
        <InputDisable v-else :text="productInfoState.lotNumber" />
      </div>
    </div>
    <div class="product-informations-form name">
      <div class="product-informations-field">
        <div class="product-informations-field-title">
          <div class="text">{{ $t('cfp_emissions_product.label_start_date') }}</div>
        </div>
        <TextFieldDate :key="key" :isDisabled="((!canEditProductInfo || isReadOnly) && isDetailProduct) || (!productInfoState.lotNumber && !isReadOnly)" :inputValue="productInfoState.startDate" @update:inputValue="handleUpdateProduct($event, 'startDate')"/>
      </div>
      <div class="product-informations-field">
        <div class="product-informations-field-title">
          <div class="text">{{ $t('cfp_emissions_product.label_end_date') }}</div>
        </div>
        <TextFieldDate :key="key" :isDisabled="((!canEditProductInfo || isReadOnly) && isDetailProduct) || (!productInfo.lotNumber && !isReadOnly)" :inputValue="productInfoState.endDate" @update:inputValue="handleUpdateProduct($event, 'endDate')"/>
      </div>
    </div>

    <!-- location -->
    <div class="product-informations-form">
      <div class="product-informations-field" style="width: 100%;">
        <div class="product-informations-field-title">
          <div class="text">{{ $t('cfp_emissions_product.label_location') }}</div>
        </div>

        <LocationTable
          :isReadOnly="isReadOnly"
          :organizations="productInfoState"
          :isCheckValidate="isCheckValidate"
          @updateLayerBinding="updateLayerBinding"
          @updateOrganizations="handleUpdateOrganizations"
        />
      </div>
     </div>

    <div v-if="isShowEmissionBox" class="product-informations-form name register-date-box">
      <div class="product-informations-field register-date">
        <div class="product-informations-field-title">
          <div class="text">{{ $t('cfp_emissions_product.label_register_date') }}</div>
        </div>
        <InputDisable :text="productInfoState.created_at" />
      </div>
      <div class="product-informations-field note">
        <div class="product-informations-field-title">
          <div class="text">{{ $t('reduction.label_note') }}</div>
        </div>
        <InputField v-if="isNoDisableNote" class="product-informations-field-input" @update:inputValue="updateNoteComment($event)" :inputValue="productInfoState.note" />
        <InputDisable v-else :text="productInfoState.note" />
      </div>
    </div>

    <!-- update condition when has register emissions -->
    <div v-if="isShowEmissionBox" class="product-informations-form name note-box">
      <div class="product-informations-field">
        <div class="product-informations-field-title">
          <div class="text">{{ $t('cfp_emissions_product.label_emission_per_product') }}</div>
        </div>
        <div class="product-emission">
          {{ formatBigNumber(productInfoState.product_emissions) }} <span class="unit">{{productInfoState.product_emissions_unit}}</span>
        </div>
      </div>
      <div class="product-informations-field">
        <div class="product-informations-field-title">
          <div class="text">{{ $t('cfp_emissions_product.label_emission_per_declared_unit') }}</div>
        </div>
        <div v-if="isNoSupportEmissionByProduct" class="product-no-support">{{ $t('cfp_emissions_product.label_no_support') }}</div>
        <div v-else class="product-emission">
          {{ formatBigNumber(productInfoState.declare_emissions) }} <span class="unit">{{productInfoState.declare_emissions_unit}}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import InfiniteScroll from '@/components/pulldown/InfiniteScroll';
import InputField from '@/components/products/input/InputField';
import InputDisable from '@/components/products/table/InputDisable';
import TextFieldDate from '@/components/products/input/TextFieldDate';
import LocationTable from '@/components/products/LocationTable';

import { getProductInfoByParams } from '@/api/product'

import debounce from 'lodash/debounce';
import { formatBigNumberToString } from '@/utils/number';
import { STATUS_FIELD } from '@/constants/status';
import { ROLE } from '@/constants/role';
import i18n from '@/lang/i18n';
import {mapState} from "vuex";
import Tooltip from '@/components/commonApp/Tooltip';
import { formatNumberRealNum } from '@/utils/convertNumber';

export default {
  name: 'ProductInfo',
  components: { InfiniteScroll, InputField, InputDisable, Tooltip, TextFieldDate, LocationTable },
  props: {
    declaredUnitList: {
      type: Array,
      default: [],
    },
    productInfo: {
      type: Object,
      default: () => {}
    },
    canEditProductInfo: {
      type: Boolean,
      default: false
    },
    isHasEmissionVal: {
      type: Boolean,
      default: false
    },
    isDetailProduct: {
      type: Boolean,
      default: false,
    },
    isReadOnly: {
      type: Boolean,
      default: true,
    },
    isDraftProduct: {
      type: Boolean,
      default: false,
    },
    workflowData: {
      type: Object,
      default: () => {}
    },
    isCheckValidate: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState('userData', ['currentUser']),
    isShowEmissionBox() {
      return this.productInfo?.product_id
    },
    isDisabledNote() {
      return this.productInfo?.product_id && this.productInfo?.status !== STATUS_FIELD.STATUS_DRAFT 
    },
    isNoSupportEmissionByProduct() {
      return !this.productInfo?.declareUnit || !this.productInfo?.product_quantity
    },
    isNoDisableNote() {
      return this.productInfo?.product_id &&
        (this.productInfo?.is_tmp === 1 ||
        ([STATUS_FIELD.STATUS_DRAFT,
          STATUS_FIELD.STATUS_REPRENSENT_RETURN,
          STATUS_FIELD.STATUS_RETURN].includes(this.productInfo?.status) &&
        this.productInfo?.is_tmp === 0 && this.productInfo?.is_owner === 1) ||
        (this.isApproveProductInfoInEmission && this.currentUser?.user?.role_id === ROLE.ADMIN &&
        [STATUS_FIELD.STATUS_APPROVED, STATUS_FIELD.STATUS_REPRENSENT_APPROVED].includes(this.productInfo?.status)))
    },
    isApproveProductInfoInEmission() {
      return [STATUS_FIELD.STATUS_APPROVED, STATUS_FIELD.STATUS_REPRENSENT_APPROVED].includes(this.productInfo?.product_status)
    },
   },
  data() {
    return {
      isCheckLang: i18n.locale,
      declaredUnit: null,
      isMappingNameCode: false,
      key: null,
      productErrorMessages: {
        productUnitErrorMessages: [],
        productQuantityErrorMessages: [],
        productDeclareUnitErrorMessage: '',
      },
      productInfoState: {
        name: '',
        code: '',
        unit: '',
        declareUnit: '',
        product_quantity: null,
        lotNumber: '',
        startDate: null,
        endDate: null,
        organizational_division: null,
        company_name: null,
        business_name: null,
        country: null,
        layer_3: null,
        layer_4: null,
        layer_5: null,
        layer_6: null,
        branch_id: null
      }
    };
  },
  methods: {
    getLabelDetail(val) {
      if (val) return '';
      return this.isDetailProduct && this.isReadOnly ? this.$t('cfp_emissions_product.error_not_set') : ''
    },

    handleUpdateProduct: debounce(function(newData, keyData) {
      if (newData !== this.productInfo[keyData]) {
        this.productInfoState[keyData] = newData;

        this.$emit('updateProductInfo', { 
          ...this.productInfoState, 
          code: this.productInfoState.code.trim() 
        });
      }
    }, 200),

    async autofillCodeField(value) {
      // CALL API get product code by product name
      const { name } = this.productInfoState;
      if (name?.trim()) {
        try {
          const res = await getProductInfoByParams({ name });
          this.productInfoState.code = res.data?.code || this.productInfoState.code;
          this.isMappingNameCode = Boolean(res.data?.code);
        } catch (error) {
          if (error.status = 403) {
            this.$emit('showErrorMessage', error.message);
          }
          console.error('Failed to fetch product info:', error);
        }
      }
    },
    clearDataField() {
      // TODO: Backup for 21119
      // this.productInfoState.code = '';
    },

    updateDeclareUnit(newVal) {
      this.productInfoState.declareUnit = newVal
      this.$emit('updateProductInfo', this.productInfoState)
    },
    handleCheckProductUnit(productUnit) {
      if (productUnit.length > 10) {
        this.productErrorMessages.productUnitErrorMessages.push(this.$t('cfp_emissions_product.error_maximum_10_charactors_unit'));
      }
    },
    handleCheckProductQuantity(productQuantity) {
      this.productErrorMessages.productQuantityErrorMessages = []
      if(!this.isDraftProduct && this.isDetailProduct) return
      if (this.isDetailProduct && !(!!productQuantity)) {
        this.productErrorMessages.productQuantityErrorMessages.push(this.$t('cfp_emissions_product.error_not_set'));
      } else {
        this.productErrorMessages.productQuantityErrorMessages = []
      }
    },
    handleCheckProductDeclareUnit(declareUnit) {
      if(!this.isDraftProduct && this.isDetailProduct) return
      if (this.isDetailProduct && !declareUnit) {
        this.productErrorMessages.productDeclareUnitErrorMessage = this.$t('cfp_emissions_product.error_not_set');
      } else {
        this.productErrorMessages.productDeclareUnitErrorMessage = '';
      }
    },
    updateNoteComment(newVal) {
      this.productInfoState.note = newVal;
    },
    formatBigNumber(num) {
      return formatBigNumberToString(num)
    },
    formatNumber(num) {
      return formatNumberRealNum(num)
    },
    resetErrorMessage(type) {
      this.productErrorMessages[type] = type === 'productDeclareUnitErrorMessage' ? '' : [];
    },
    handleUpdateOrganizations(organizations, branchData, layerBinding) {
      this.productInfoState = {
        ...this.productInfoState,
        ...organizations,
        branch_id: branchData?.id || null
      }
      this.$emit('updateProductInfo', this.productInfoState, layerBinding)
    },
    updateLayerBinding(layerList) {
      this.$emit('updateLayerBinding', layerList)
    }
  },
  watch: {
    productInfo: {
      handler(newVal) {
        this.productInfoState = newVal
        if(this.isDetailProduct) {
          this.handleCheckProductQuantity(newVal?.product_quantity)
          this.handleCheckProductDeclareUnit(newVal?.declareUnit)
        }
      },
      deep: true,
      immediate: true,
    },
    productInfoState: {
      handler(newVal) {
        if (newVal.declareUnit) {
          this.handleCheckProductDeclareUnit(newVal.declareUnit);
        }
        // Re-render get startDate & endDate in case data disabled
        if ((newVal.startDate || newVal.endDate) && (!this.canEditProductInfo || this.isReadOnly) && this.isDetailProduct) {
          this.key++
        }
      },
      deep: true,
    },
    isReadOnly: {
      async handler(value) {
        if(!value) {
          const { name } = this.productInfoState;
          if (name?.trim()) {
            try {
              const res = await getProductInfoByParams({ name });
              const existCode = Boolean(res.data?.code);
              this.isMappingNameCode = existCode && this.productInfoState.code === res.data?.code;
            } catch (error) {
              console.error('Failed to fetch product info:', error);
            }
          }
        }
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.product-informations {
  display: flex;
  flex-flow: column;
  background: $bgCusLight;
  margin: 0 -20px;

  &-header {
    color: $monoWhite;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0.42px;
    height: 44px;
    padding: 8px 20px;
    gap: 16px;
    background: $bgDeep;
  }
  &-form {
    padding: 16px 40px;
    display: flex;
    flex-flow: column;
    gap: 16px;
    .product-informations-field {
      display: flex;
      flex-flow: column;
      gap: 8px;
      min-height: 72px;
      justify-content: space-between;
      &-title {
        display: flex;
        align-items: center;
        gap: 8px;
        font-style: normal;
        font-weight: 500;
        .text {
          font-size: 14px;
          line-height: 24px;
          letter-spacing: 0.42px;
          font-weight: 500;
          color: $monoBlack;
        }
        .required-text {
          background: $goldMid;
          color: $monoWhite;
          border-radius: 4px;
          display: flex;
          padding: 0px 4px 2px 4px;
          font-size: 11px;
          line-height: 18px;
          letter-spacing: 0.33px;
          width: max-content;
          height: 20px;
        }
      }
    }
    &.name {
      .product-informations-field {
        .product-emission {
          font-family: 'Century Gothic Pro';
          font-size: 34px;
          font-weight: 400;
          line-height: 40px;
          letter-spacing: 0.03em;
          color: $goldMid;
          word-break: break-all;
          .unit {
            font-size: 14px;
            font-weight: 400;
            line-height: 24px;
            letter-spacing: 0.05em;
          }
        }
        .product-no-support {
          font-size: 14px;
          font-weight: 400;
          line-height: 24px;
          letter-spacing: 0.42px;
          color: $monoBlack;
        }
      }
    }
    &.lot-number {
      .product-informations-field {
        width: 100%;
      }
    }
  }
  &.boundary {
    background: $bgExtraLight;
  }
}

@include desktop {
  .product-informations {
    display: flex;
    flex-flow: column;
    background: $bgCusLight;
    margin: 0;

    &-header {
      color: $monoWhite;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px;
      letter-spacing: 0.42px;
      height: 44px;
      padding: 8px 20px;
      gap: 16px;
      background: $bgDeep;
    }
    &-form {
      flex-direction: row;
      padding: 16px 40px 22px;
      gap: 20px;
      width: 100%;
      &.name {
        .product-informations-field {
          width: calc((100% - 20px) / 2);
        }
        &.note-box {
          padding: 16px 40px 24px 40px;
        }
        &.register-date-box {
          .product-informations-field {
            &.register-date {
              width: calc((100% - 40px) / 3);
            }
            &.note {
              width: calc(100% - ((100% - 40px) / 3));
            }
          }
        }
      }

      &.unit {
        .product-informations-field {
          width: calc((100% - 40px) / 3);
        }
      }
    }
    &.boundary {
      background: $bgExtraLight;
    }
  }
}
</style>