<template>
  <div class="input-disabled" :class="isNotSetData && 'input-notset-data'">
    <div
      ref="inputDisable"
      class="input-disable"
      :class="isSmallInput && 'small-input'"
      @mouseover="handleTooltip($event)"
      @mouseleave="handleTooltip($event, 'mouseleave')"
    >
      {{ text }}
    </div>
    <div v-if="isNotSetData" class="label_notsetdata">{{ $t('cfp_emissions_product.error_not_set') }}</div>
    <div v-if="showTooltip" class="tooltip" v-html="text"></div>
  </div>
</template>

<script>
import { getWidthText } from '@/utils/calcTextWidth';
export default {
  name: "InputDisable",
  props: {
    text: {
      type: [String, Number],
      default: ''
    },
    isSmallInput: {
      type: Boolean,
      default: false
    },
    isNotSetData: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      showTooltip: false,
    }
  },
  computed: {
    isShowTooltip() {
      const divWidth = this.$refs.inputDisable?.clientWidth;
      const textWidth = getWidthText(this.text, {fontSize: '14px', fontWeight: 500, letterSpacing: '0.42px' });
      return divWidth < textWidth ? true : false;
    }
  },
  methods: {
    handleTooltip(event, type) {
      if (!this.isShowTooltip) return;
      if (type === 'mouseleave') {
        event.target.style.cursor = 'default';
        this.showTooltip = false;
      } else {
        event.target.style.cursor = 'pointer';
        this.showTooltip = true;
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.input-disable {
  border-radius: 4px;
  border: 1px solid var(--Devider-Black, rgba(42, 42, 48, 0.10));
  height: 40px;
  padding: 7px 16px 9px 16px;
  color: $monoBlack;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.42px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
    
  &.small-input {
    font-size: 11px;
    line-height: 16px;
    min-height: 28px;
    font-weight: 700;
    padding: 4px 10px 6px 10px;
    height: 28px;
  }
}

.input-notset-data {
  .label_notsetdata {
    color: $redMid;
    font-weight: 500;
    font-size: 11px;
    line-height: 12px;
    letter-spacing: 0.05em;
    margin-top: 4px;
    text-align: left;
    padding: 0px 11px;
  }
  .input-disable {
    border: 2px solid $redMid;
  }
}

.input-disabled {
  position: relative;
  .tooltip {
    position: absolute;
    width: max-content;
    background: $monoWhite;
    display: block;
    justify-content: left;
    border-radius: 4px;
    color: $monoBlack;
    margin: 0 auto;
    font-size: 15px;
    font-weight: 500;
    padding: 10px;
    height: unset;
    max-width: 100%;
    word-break: break-all;
    left: 0;
    top: 40px;
    z-index: 9999;
    box-shadow: 0px 36px 33px rgba(160, 181, 186, 0.0744044),
      0px 13.1406px 12.0455px rgba(160, 181, 186, 0.10649), 0px 6.37951px 5.84789px rgba(160, 181, 186, 0.13351),
      0px 3.12736px 2.86674px rgba(160, 181, 186, 0.165596), 0px 1.23656px 1.13351px rgba(160, 181, 186, 0.24);
  }
}
</style>