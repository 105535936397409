<template>
  <div class="location-table" v-click-outside="onClickOutside">
    <data-table
      :data-source="productOrganizationCollection"
      :grid-columns="locationGridColumns"
      :init-grid="initializeGrid"
      :rowFocus="1"
      :showFocus="false"
      :allowAddNew="false"
      :isShowToolbar="false"
      :isResizeCustome="false"
      :isReadOnly="isReadOnly"
      :isRemoveIndex="true"
      @flexGridInitialDone="onFlexGridInitialDone"
    />
  </div>
</template>

<script>
//UI
import DataTable from '@/components/category/data-table';

//Logic
import Organization from '@/concerns/products/organization';
import { getListDetailBranch } from '@/api/registerData';
import { getListOrganizationalApi } from '@/api/facility';
import { prepareBranchData } from '@/utils/registerData';
import { CollectionView } from "@mescius/wijmo";
import { handlerAddFilterData, handlerFilterData, getBranchId } from '@/concerns/utils/filter-data';
export default {
  name: "LocationTable",
  components: { DataTable },
  props: {
    organizations: {
      type: Object,
      default: () => {}
    },
    isReadOnly: {
      type: Boolean,
      default: false
    },
    isCheckValidate: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      organizationPattern: Organization,
      locationGridColumns: [],
      branchData: null,
      isReadOnlyLocation: false,
      listLayer: null,
      layerBinding: [],
      listOrganizational: [],
      productOrganizationCollection: null
    }
  },
  async mounted() {
    await Promise.all([
      this.getDetailBranch(),
      this.getDataOrganization(),
    ])

    this.getGridFacility()
    this.initialView()
  },
  methods: {
    onClickOutside(e) {
      this.productOrganizationFlexGrid.select(-1 , -1);
    },
    getGridFacility() {
      this.locationGridColumns = this.organizationPattern.header(this.listLayer, this.listOrganizational, this.isReadOnlyLocation, this.branchData)
    },
    initializeGrid(flexGrid) {
      this.productOrganizationFlexGrid = flexGrid;
      let filterIndex = {};
      let selectedFirst = null;
      let previousCellData = null;

      flexGrid.cellEditEnded.addHandler((s, e) => {
        const { row } = e.range;
        const view = s.collectionView;
        const source = view.sourceCollection;
        const currentItem = source[row] || {};
        if(currentItem.organizational_division && this.branchData.organizational_division.length <= 0) {
          currentItem.organizational_division = null
        }
        handlerFilterData(
          s, e,
          this.branchData.filterPatternList,
          filterIndex,
          selectedFirst,
          previousCellData,
          false,
        );
        this.updateDataToParent(currentItem)
      });

      flexGrid.beginningEdit.addHandler((s, e) => {
        const { row, col } = e.range;
        const cellData = s.getCellData(row, col, false);
        previousCellData = cellData;
        handlerAddFilterData(
          s, e,
          filterIndex,
          selectedFirst,
          this.$store.state.registerData,
          this.branchData
        );
      });

      flexGrid.pasted.addHandler((s, e) => {
        const view = s.collectionView;
        const source = view.sourceCollection;
        const currentItem = source[0];
        if(currentItem.organizational_division && this.branchData.organizational_division.length <= 0) {
          currentItem.organizational_division = null
        }
        this.updateDataToParent(currentItem)
      })

      // flexGrid.gotFocus.addHandler(() => {
      //   this.productOrganizationFlexGrid.select(-1 , -1);
      // })
    },
    getErrorLocation() {
      this.productOrganizationCollection.getError = this.getError;
    },
    getError(item, propName) {
      const branchData = this.getBranchDataByItem(item)
      if(!propName) return null
      if(!branchData?.id) {
        return this.$t('new_validate.error_mapping_layer', { listLayer: this.listLayer.map(item => item.layer_name)?.join('/') })
        return `入力された組織区分/法人名/${this.listLayer.map(item => item.layer_name)}の組み合わせが存在しません。`;
      }
    },
    async getDetailBranch() {
      const response = await getListDetailBranch()
      this.branchData = prepareBranchData(response)
      this.listLayer = response.layer;
      this.layerBinding = ['organizational_division', 'company_name'].concat(response.layer.map(item => item.layer_field))
      this.$emit('updateLayerBinding', this.layerBinding)
    },
    async getDataOrganization() {
      try {
        const listOrganizationalApiResponse = await getListOrganizationalApi();
        this.getListOrganization(listOrganizationalApiResponse.data);
      } catch (error) {
        console.warn(error)
      }
    },
    getListOrganization(data) {
      this.listOrganizational = [];
      data.forEach((item) => {
        this.listOrganizational.push({
          id: item.id,
          value: item.type,
        });
      });
    },
    onFlexGridInitialDone(productOrganizationFlexGrid) {
      this.productOrganizationFlexGrid = productOrganizationFlexGrid;
    },
    initialView() {
      const rowData = this.organizations?.branch || this.organizations
      this.productOrganizationCollection = new CollectionView([rowData], {
        trackChanges: true,
      });
    this.productOrganizationFlexGrid.columnGroups = this.organizationPattern.header(this.listLayer, this.listOrganizational, this.isReadOnlyLocation, this.branchData)
    },
    updateDataToParent(organizations) {
      const branchData = this.getBranchDataByItem(organizations);
      const branchObj = {}
      this.layerBinding.forEach(itemKey => branchObj[itemKey] = organizations[itemKey])
      const fullDataProductInfo = {
        ...this.organizations,
        ...branchObj
      }
      const isRegisterProduct = this.$route.name === 'RegisterProduct'
      const rowData = isRegisterProduct ? branchObj : fullDataProductInfo
      this.$emit('updateOrganizations', rowData, branchData)
    },
    getBranchDataByItem(organizations) {
      let layerItem = {}
      this.layerBinding.forEach(itemKey => {
        layerItem[itemKey] = organizations[itemKey]
      })
      const branchData = getBranchId(this.branchData.filterPatternList, layerItem);
      return branchData
    }
  },
  watch: {
    organizations: {
      handler(newVal, oldVal) {
        const isOrganizationChangeInDetail = !!(this.layerBinding?.some(key => newVal[key] !== oldVal[key]) && (this.$route.params?.id || this.$route.params?.emissionId))
        if(!this.productOrganizationCollection || this.productOrganizationCollection.itemsEdited.length || this.$route.name === 'RegisterProduct' || !isOrganizationChangeInDetail) return
        // only update grid in first view detail data
        this.productOrganizationCollection = new CollectionView([newVal], {
          trackChanges: true,
        });
      },
      deep: true,
      immediate: true
    },
    $route : {
      async handler(newVal, oldVal) {
        if(oldVal?.params?.id && newVal?.name === 'RegisterProduct') {
          this.productOrganizationCollection = new CollectionView([newVal], {
            trackChanges: true,
          });
        }
      }
    },
    isCheckValidate(newVal) {
      if(newVal) {
        this.getErrorLocation()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.location-table {
  ::v-deep .hide-filter {
    &.wj-header {
      .wj-btn {
        display: none;
      }
    }
  }
  ::v-deep .wj-rowheaders {
    .wj-header {
      .wj-glyph-pencil {
        display: none;
      }
    }
  }
}

</style>