<template>
  <div>
    <draggable
      v-bind="dragOptions"
      :list="boundaries"
      class="boundary-wrapper"
      id="boundary-wrapper"
      @start="onDragStart"
      @end="onDragEnd"
    >
      <div class="boundary-item" v-for="(boundaryItem, boundaryIndex) in boundaries"
        :key="boundaryIndex"
        :class="isDisableDragDrop"
      >
        <InputDisable v-if="isBoundaryForEmission" :text="boundaryItem.name" />
        <EmissionPerProduct class="item-perproduct" v-if="isBoundaryForEmission"
          :isCalcTotalEmissions="true"
          :workflowData="workflowData"
          :boundary="boundaryItem"
        />
        <InputField 
          v-if="!isBoundaryForEmission && !disableBoundaryInput" 
          :inputValue="boundaryItem.name"
          :isReadOnly="disableBoundaryInput"
          v-model="boundaryItem.name"
          class="boundary-input"
          @focus="handleFocusInput('boundary')"
          @blur="handleBlurInput"
        />
        <InputDisable v-if="!isBoundaryForEmission && disableBoundaryInput" :text="boundaryItem.name" class="mb-4" />
        <div class="wrapper-parent">
          <draggable 
            :options="dragProcessOptions"
            :list="boundaryItem.processes"
            class="process-wrapper"
            @start="onDragStart"
            @end="onDragEnd"
            :class="isDisableDragDrop"
          >
            <div
              class="process-item"
              v-for="(process, processIndex) in boundaryItem.processes"
              :key="`itemprocess-${processIndex}`"
            >
              <InputFieldSmall 
                v-if="!isBoundaryForEmission && !disableBoundaryInput" 
                v-model="process.name"
                :inputValue="process.name" 
                class="process-input" 
                :label="labelProcessInput"
                :isReadOnly="disableBoundaryInput" 
                @focus="handleFocusInput('process')"
                @blur="handleBlurInput"
              />
              <InputDisable v-if="!isBoundaryForEmission && disableBoundaryInput" :text="process.name" isSmallInput class="" />
              <div v-if="!isBoundaryForEmission" class="process-cutoff-label" :class="{'disabled': disableBoundaryInput}">
                <div class="checkbox-container">
                  <input
                    :id="process.name"
                    type="checkbox"
                    :name="process.name"
                    v-model="process.cut_off"
                    :disabled="disableBoundaryInput"
                    :class="{'disabled': disableBoundaryInput}"
                    @change="updateCutoffProcessItem(boundaryIndex, processIndex)"
                    @mousedown="updateCutoffProcessItem(boundaryIndex, processIndex)"
                  />
                  <span class="checkmark"></span>
                </div>
                <label class="label" :for="process.name">{{ $t("product_boundary.checkbox_process_cutoff") }}</label>
              </div>
              <ButtonCustome
                v-if="!isBoundaryForEmission && !disableBoundaryInput"
                icon="products/process-delete.svg"
                iconHover="products/process-delete-hover.svg"
                iconDisable="products/process-delete-disable.svg" 
                typeIcon="image"
                :isSmallButton="true"
                class="process-delete"
                :buttonText="deleteBtn"
                :isDisable="disableBoundaryInput"
                @action="handleDeleteProcessItem(boundaryIndex, processIndex)"
              >
              </ButtonCustome>
              <!-- case disableBoundaryInput -->
              <InputDisable v-if="isBoundaryForEmission" :isSmallInput="true" :text="process.name" />
              <EmissionPerProduct v-if="isBoundaryForEmission" class="item-perproduct"
                :workflowData="workflowData"
                :process="process"
              />

              <v-btn v-if="isBoundaryForEmission" depressed class="button-edit" @click="redirectToRegisterEmissions(process)">
                <img src="@/assets/images/products/edit.svg" alt="" class="icon" />
                {{ $t("cfp_emissions_product.button_edit_product_information") }}
              </v-btn>
            </div>
          </draggable>
        </div>
        <div v-if="!isBoundaryForEmission && !disableBoundaryInput" class="boundary-button">
          <Button :label="addProcessBtn" :isDisable="disableBoundaryInput" :key="reRenderBtnKey" @action="addProcessItem(boundaryIndex)" @mouseover="hanleMouseover()" @mouseleave="handleMouseleave()" />
          <Button :label="deleteBtn" :isDisable="disableBoundaryInput" @action="handleDeleteBoundaryItem(boundaryIndex)" @mouseover="hanleMouseover()" @mouseleave="handleMouseleave()" />
        </div>
      </div>
    </draggable>
    <div class="boundary-add-wrapper" v-if="!disableBoundaryInput">
      <Button class="boundary-add" icon="products/boundary-add.svg" iconHover="products/boundary-add-hover.svg" :label="addBoundaryBtn" :isDisable="disabledBoundary" @action="addBoundaryItem" />
    </div>
    <question-popup
      :confirm="true"
      :dialog="questionDeleteProcessPopup"
      :message="questionDeleteProcessMessage"
      :isSaveButtonDelete="true"
      :confirmText="$t('popup.button_delete')"
      @close="questionDeleteProcessPopup = false"
      @submit="deleteProcessItem"
    />
    <question-popup
      :confirm="true"
      :confirmText="$t('popup.button_delete')"
      :dialog="questionDeleteBoundaryPopup"
      :message="questionDeleteBoundaryMessage"
      :isSaveButtonDelete="true"
      @close="questionDeleteBoundaryPopup = false"
      @submit="deleteBoundaryItem"
    />
  </div>
</template>
<script>
// UI import
import draggable from 'vuedraggable';
import InputField from '@/components/products/input/InputField';
import InputFieldSmall from '@/components/products/input/InputFieldSmall';
import Button from '@/components/commonApp/Button';
import InputDisable from '@/components/products/table/InputDisable';
import ButtonCustome from '@/components/commonApp/ButtonIconCustom';
import EmissionPerProduct from '@/components/products/table/EmissionPerProduct';
import QuestionPopup from '@/components/dialogs/question-popup';
import { STATUS_FIELD } from '@/constants/status';
import { DEFAULT_BOUNDARY } from '@/constants/products/define-data';
import _ from 'lodash';

export default {
  components: {
    draggable,
    InputField,
    InputFieldSmall,
    Button,
    InputDisable,
    EmissionPerProduct,
    ButtonCustome,
    QuestionPopup
  },
  props: {
    dataList: {
      type: Array,
      default: () => [],
    },
    isBoundaryForEmission: {
      type: Boolean,
      default: false
    },
    isDetailProduct: {
      type: Boolean,
      default: false
    },
    workflowData: {
      type: Object,
      default: () => {}
    },
    isDisableInputProductData: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      boundaries: _.cloneDeep(DEFAULT_BOUNDARY),
      dragOptions: {
        animation: 200,
        disabled: false,
        ghostClass: "ghost",
        scrollSensitivity: 100,
        swapThreshold: 1,
        scroll: true,
        forceAutoScrollFallback: true,
        forceFallback: true,
        bubbleScroll: true
      },
      dragProcessOptions: {
        animation: 200,
        disabled: false,
        ghostClass: "ghost",
        scrollSensitivity: 100,
        swapThreshold: 1,
        scroll: true,
        forceAutoScrollFallback: true,
        forceFallback: true,
        bubbleScroll: true
      },
      questionDeleteProcessPopup: false,
      processIndex: null,
      boundaryIndex: null,
      questionDeleteProcessMessage: '',
      questionDeleteBoundaryPopup: false,
      questionDeleteBoundaryMessage: '',
      reRenderBtnKey: 0,
    };
  },
  mounted() {
    this.$emit('updateBoundaries', this.boundaries);
  },
  computed: {
    deleteBtn() {
      return this.$t("product_boundary.button_delete");
    },
    labelProcessInput() {
      return this.$t("product_boundary.label_process_name");
    },
    addBoundaryBtn() {
      return this.$t("product_boundary.button_add_boundary");
    },
    addProcessBtn() {
      return this.$t("product_boundary.button_add_process");
    },
    questionMessage() {
      return this.$t("cfp_emissions_product.message_delete_process");
    },
    disabledBoundary() {
      return [
        STATUS_FIELD.STATUS_SUBMIT,
        STATUS_FIELD.STATUS_APPROVED,
        STATUS_FIELD.STATUS_REPRENSENT_APPROVED
      ].includes(this.workflowData?.status) || this.isBoundaryForEmission;
    },
    disableBoundaryInput() {
      return this.isDisableInputProductData
    },
    isDisableDragDrop() {
      return this.disabledBoundary ? 'disabled': '';
    }
  },
  methods: {
    handleDeleteProcessItem(boundaryIndex, processIndex) { //set index off boundary, process & message confirm delete process by name 
      this.dragProcessOptions.disabled = true;
      this.dragOptions.disabled = true;

      this.boundaryIndex = boundaryIndex
      this.processIndex = processIndex
      this.questionDeleteProcessPopup = true
      this.questionDeleteProcessMessage = this.$t("cfp_emissions_product.message_delete_process")
      this.dragProcessOptions.disabled = false;
      this.dragOptions.disabled = false;
    },
    handleDeleteBoundaryItem(boundaryIndex) {
      this.dragOptions.disabled = true
      this.boundaryIndex = boundaryIndex
      this.questionDeleteBoundaryPopup = true
      this.questionDeleteBoundaryMessage= this.$t("cfp_emissions_product.message_delete_boundary")
      this.dragOptions.disabled = false
    },
    handleGetProcessAndBoundarySelected() {
      const boundarySelected = this.boundaries[this.boundaryIndex]
      const processSelected = boundarySelected["processes"][this.processIndex]
      return { boundarySelected, processSelected }
    },
    addBoundaryItem() {
      this.boundaries.push(
        {
          name: "",
          processes: [
            { name: "", cut_off: false },
          ]
        }
      );
      const element = document.getElementById("boundary-wrapper");
      setTimeout(() => {
        element.scrollLeft = element.scrollWidth - element.clientWidth;
      }, 100);
      this.$emit("updateBoundaries", this.boundaries);
    },
    addProcessItem(boundaryIndex) {
      this.dragOptions.disabled = true
      this.boundaries[boundaryIndex]["processes"].push(
        { name: "", cut_off: false },
      );
      this.$emit("updateBoundaries", this.boundaries);
      this.dragOptions.disabled = false
    },
    deleteProcessItem() {
      const { boundarySelected, processSelected } = this.handleGetProcessAndBoundarySelected()
      if (!boundarySelected || !processSelected) return
        
      boundarySelected["processes"].splice(this.processIndex, 1)
      this.questionDeleteProcessPopup = false
      this.$emit("updateBoundaries", this.boundaries)
    },
    deleteBoundaryItem() {
      const { boundarySelected } = this.handleGetProcessAndBoundarySelected()
      if(!boundarySelected) return

      this.boundaries.splice(this.boundaryIndex, 1);
      this.questionDeleteBoundaryPopup = false
      this.$emit("updateBoundaries", this.boundaries);
    },
    updateCutoffProcessItem(boundaryIndex, processIndex) {
      this.dragProcessOptions.disabled = true;
      this.boundaryIndex = boundaryIndex
      this.processIndex = processIndex
      const { boundarySelected, processSelected } = this.handleGetProcessAndBoundarySelected()
      if (!boundarySelected || !processSelected) return
        
      this.$emit("updateBoundaries", this.boundaries);
      this.dragProcessOptions.disabled = false;
    },
    onDragStart(e) {
      e.item.style.visibility = "hidden";
      window.getSelection().removeAllRanges();
    },
    onDragEnd(e) {
      this.$emit("updateBoundaries", this.boundaries);
      e.item.style.visibility = "visible";
    },
    handleFocusInput(type = 'process') {
      this.dragOptions.disabled = true
      if(type === 'process') {
        this.dragProcessOptions.disabled = true
      }
    },
    handleBlurInput() {
      this.dragOptions.disabled = false
      this.dragProcessOptions.disabled = false
      this.$emit("updateBoundaries", this.boundaries)
    },
    redirectToRegisterEmissions(process) {
      if (process?.id) {
        this.$router.push({name: "RegisterEmissions", params: {processId: process.id}, query: {...this.$route.query}}).catch(() => {});
      }
    },
    hanleMouseover() {
      this.dragOptions.disabled = true;
    },
    handleMouseleave() {
      this.dragOptions.disabled = false;
      this.reRenderBtnKey++;
    },
  },
  watch: {
    dataList: {
      handler() {
        if (!this.isDetailProduct) return;
        this.boundaries = [...this.dataList];
      },
      deep: true,
      immediate: true,
    },
    workflowData: {
      handler() {
        this.dragOptions.disabled = this.disabledBoundary
        this.dragProcessOptions.disabled = this.disabledBoundary
      },
      deep: true,
      immediate: true
    },
    isDetailProduct: {
      handler(value) {
        if (!value) {
          this.boundaries = _.cloneDeep(DEFAULT_BOUNDARY);
        }
      },
      deep: true,
      immediate: true,
    }
  }
};
</script>
<style lang="scss" scoped>
.boundary-wrapper {
  display: flex;
  flex-flow: row;
  gap: 16px;
  padding: 16px;
  max-width: 100%;
  background: $bgExtraLight;
  overflow-x: scroll;
  overflow-y: hidden;
  &::-webkit-scrollbar {
    height: 8px !important;
    border-radius: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $bgMid;
    border-radius: 4px;
    z-index: 1000;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: $bgMid;
  }

  &::-webkit-scrollbar-corner {
    background: $bgMid;
  }
  
  .boundary-item {
    width: 250px;
    min-width: 250px;
    background: $bgCusLight;
    padding: 16px;
    cursor: move;
    &.disabled {
      cursor: auto;
    }
    .boundary-input {
      width: 218px;
      height: 40px;
      margin-bottom: 16px;
    }
    .wrapper-parent {
      max-height: 380px;
      overflow-y: auto;
      &::-webkit-scrollbar {
        width: 16px;
      }
      &::-webkit-scrollbar-thumb {
        background-color: $bgThumbScrollbar;
        border-radius: 10px;
        border: 4px solid rgba(0, 0, 0, 0);
        background-clip: padding-box;
      }
      
      .process-wrapper {
        display: flex;
        flex-flow: column;
        gap: 8px;
        margin-bottom: 16px;
        .process-item {
          display: flex;
          flex-flow: column;
          padding: 16px 16px 8px 16px;
          background: $bgExtraLight;
          border-bottom: 1px solid rgba(42, 42, 48, 0.10);
          .process-cutoff-label {
            color: $monoBlack;
            font-size: 14px;
            font-weight: 500;
            line-height: 24px;
            letter-spacing: 0.42px;
            display: flex;
            align-items: center;
            gap: 6px;
            margin: 8px 0 2px;
            .label {
              cursor: pointer;
            }
            .checkbox-container {
              display: flex;
              position: relative;
              -webkit-user-select: none;
              -moz-user-select: none;
              -ms-user-select: none;
              user-select: none;
              width: 16px;
              height: 16px;
            }
            input[type='checkbox'] {
              position: absolute;
              top: 0;
              left: 0;
              right: 0;
              bottom: 0;
              z-index: 1;
              opacity: 0;
              cursor: pointer;
              &:checked ~ .checkmark {
                background-color: $blueMid;
                &::after {
                  display: block;
                }
              }
              &:hover ~ .checkmark {
                border: 1px solid rgba(0, 0, 0, 0.4);
              }
              &:checked:hover ~ .checkmark {
                border: 1px solid rgba(0, 0, 0, 0.25);
                box-shadow: 0px 0px 4px 10px rgba(0, 0, 0, 0.15) inset;
              }
              &.disabled {
                stroke-width: 0.5px;
                stroke: rgba(0, 0, 0, 0.25);
                box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.08) inset;
                background: $grayDark !important;
                &:hover {
                  cursor: unset;
                }
              }
              &.disabled ~ .checkmark {
                opacity: 0.8;
                background: $grayDark;
              }
            }
            .checkmark {
              position: absolute;
              top: 0;
              left: 0;
              right: 0;
              bottom: 0;
              background-color: $monoWhite;
              border-radius: 4px;
              box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.08) inset;
              border: 1px solid rgba(0, 0, 0, 0.25);
              &::after {
                content: '';
                position: absolute;
                left: 4px;
                top: 0px;
                width: 6px;
                height: 11px;
                border: solid $monoWhite;
                border-width: 0 2px 2px 0;
                -webkit-transform: rotate(45deg);
                -ms-transform: rotate(45deg);
                transform: rotate(45deg);
                display: none;
              }
            }
            &.disabled {
              .label {
                cursor: unset;
              }
            }
          }
          .process-delete {
            width: max-content;
            min-width: 61px;
            height: 28px !important;
            padding: 4px 8px 6px !important;
            margin: 0 auto;
            ::v-deep {
              .v-btn__content {
                font-size: 11px;
                font-style: normal;
                font-weight: 700;
                line-height: 18px;
                letter-spacing: 0.33px;
                color: $monoBlack;
                .icon {
                  width: 16px;
                  height: 16px;
                  margin-right: 6px !important;
                }
              }
            }
          }
        }

        &::-webkit-scrollbar {
          width: 16px;
        }
        &::-webkit-scrollbar-thumb {
          background-color: $bgThumbScrollbar;
          border-radius: 10px;
          border: 4px solid rgba(0, 0, 0, 0);
          background-clip: padding-box;
        }
        &.disabled {
          cursor: auto;
        }
      }
    }
    .boundary-button {
      display: flex;
      flex-flow: row;
      gap: 8px;
      text-transform: capitalize;
      .common-btn {
        width: 105px !important;
        ::v-deep {
          .v-btn__content {
            text-transform: capitalize !important;
          }
        }
      }
    }
    .item-perproduct {
      margin: 10px 0 16px 0;
    }
    .button-edit {
      width: 61px;
      height: 28px;
      margin: 0 auto;
      border-radius: 4px;
      border: 1px solid $monoBlackDark;
      background: $monoOffWhite;
      font-weight: 700;
      padding: 4px 8px 6px 8px;
      font-size: 11px;
      .icon {
        margin-right: 6px;
      }
    }
  }
}
.boundary-add-wrapper {
  margin: 16px 0 80px;
  display: flex;
  justify-content: center;
  .boundary-add {
    width: 300px;
  }
}
.ghost {
  opacity: 0.5;
}
</style>